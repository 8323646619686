import { Outlet } from 'react-router-dom'
import logo from '../HeaderComponent/otter.png';
import userlogo from '../HeaderComponent/user.png';
import applicationgif from '../HeaderComponent/otterswim.gif'
import { Helmet } from 'react-helmet';

import Header from '../HeaderComponent/Header'

export default function Root() {
    return (
        <>
        <Helmet title='AnOtterSide' logo={logo} />
        <Header 
            applicationTitle={'An Otter Side'}
            applicationSubtitle={`D's World`} 
            applicationlogo={logo} 
            userlogo={userlogo}
            applicationgif={applicationgif} />
        <div className='content-background'>
            <div className='content-block'>
                <div className='content'>
                    <Outlet />
                </div>
            </div>
        </div>
        </>
    );
};