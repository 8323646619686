import './App.css'
import { useEffect, useState } from 'react'
import { Grid,Switch } from '@material-ui/core'
import { Stack } from '@mui/material';
import restart from './restart.png'
import ConfettiExplosion from 'react-confetti-explosion';
import Popup from 'react-animated-popup';

function Squares({ value,onSquareClick }) {
    return (
        <button className="square" onClick={onSquareClick}>
            {value}
        </button>
    );
};

function Board({ 
    BoardGrid,SetBoardGrid
    ,CurrentPlayer,SetCurrentPlayer
    ,isGameOver,setIsGameOver
    ,GameMessage,SetGameMessage
    ,IsExploding,SetIsExploding
    ,ShowLosePopup,SetShowLosePopup
    ,SetGamesPlayed,SetGamesLost
}) {
    function ProcessMove(Index) {
        if (BoardGrid[Index] || isGameOver) {return;}
        const NewBoard=BoardGrid.slice();
        NewBoard[Index]=CurrentPlayer;
        SetBoardGrid(NewBoard);
        if (GameOverCheck(NewBoard)) {return;}
    };

    useEffect(()=>{
        if (CurrentPlayer==='O') {
            GodBot(BoardGrid);
        } return;
    });
    
    const WinningCombi=[[0,1,2],[3,4,5],[6,7,8],[0,3,6],[1,4,7],[2,5,8],[0,4,8],[2,4,6]]

    function GameOverCheck(Board) {
        for (let i=0;i<WinningCombi.length;i++) {
            const [a,b,c]=WinningCombi[i];
            if (Board[a] && Board[a]===Board[b] && Board[a]===Board[c]) {
                setIsGameOver(true); 
                SetGameMessage('Game Over: Winner is '+CurrentPlayer);
                SetIsExploding(true);
                SetShowLosePopup(true);
                SetGamesPlayed(x=>x+1);
                SetGamesLost(x=>x+1);
                return;
            } else if (Board.filter(x=>x).length===9) {
                setIsGameOver(true); 
                SetGameMessage('Game Over: Match Ended As A Draw');
                SetGamesPlayed(x=>x+1);
                return;
            } else {
                SetCurrentPlayer(CurrentPlayer==='X'?'O':'X');
                SetGameMessage('Current Player: X');
            }
        };
    };
    
    function GodBot(Board) {
        if (!Board[4]) {
            ProcessMove(4);
            console.log('move1');
        } else 
        if (Board[4]==='X' && !Board[0]) {
            ProcessMove(0);
            console.log('move2');
        } else
        if (OpportunityCheck(Board,false)) {
            ProcessMove(OpportunityCheck(Board,false));
            console.log('move3');
        } else 
        if (OpportunityCheck(Board,false)===0) {
            ProcessMove(0);
            console.log('move4');
        } else 
        if (OpportunityCheck(Board,true)) {
            ProcessMove(OpportunityCheck(Board,true));
            console.log('move5');
        } else 
        if (OpportunityCheck(Board,true)===0) {
            ProcessMove(0);
            console.log('move6');
        } else 
        if (Board.filter(x=>x==='X').length<=2) {
            if (Board[5]==='X' && Board[7]==='X') {ProcessMove(8)} else
            if (!Board[0]) {ProcessMove(0)} else 
            if (!Board[2] && !Board[1]) {ProcessMove(2)} else 
            if (!Board[6]) {ProcessMove(6)} else 
            if (!Board[8]) {ProcessMove(8)}
            console.log('move7');
        } else
        if (BestMoveCheck(Board,false)) {
            ProcessMove(BestMoveCheck(Board,false));
            console.log('move12');
        } else 
        {
            for (let i=0;i<9;i++) {
                if (!Board[i]) {
                    ProcessMove(i);
                    console.log('move13');
                    return;
                }
            }
        };
    };

    function OpportunityCheck(Board,isX) {
        for (let i=0;i<WinningCombi.length;i++) {
            const [a,b,c]=WinningCombi[i];
            const p1=isX?'X':'O';
            const p2=isX?'O':'X';
            if (Board[a]===p1 && (Board[a]===Board[b]) && Board[c]!==p2) {
                return c;
            } else 
            if (Board[a]===p1 && (Board[a]===Board[c]) && Board[b]!==p2) {
                return b;
            } else 
            if (Board[b]===p1 && (Board[b]===Board[c]) && Board[a]!==p2) {
                return a;
            }
        };
    };

    function BestMoveCheck(Board,isX) {
        for (let i=0;i<WinningCombi.length;i++) {
            const [a,b,c]=WinningCombi[i];
            const p1=isX?'X':'O';
            if (Board[a]===p1 && !Board[b] && !Board[c]) {
                return c;
            } else 
            if (Board[b]===p1 && !Board[a] && !Board[c]) {
                return a;
            } else 
            if (Board[c]===p1 && !Board[a] && !Board[b]) {
                return b;
            }
        };
    };

    return (
        <>
        <Grid container direction='column' className='game-container'>
        <div className='game-message'>{GameMessage}</div><br></br>
        <div className='board'>
            <div className='board-row'>
                <Squares value={BoardGrid[0]} onSquareClick={()=>ProcessMove(0)}/>
                <Squares value={BoardGrid[1]} onSquareClick={()=>ProcessMove(1)}/>
                <Squares value={BoardGrid[2]} onSquareClick={()=>ProcessMove(2)}/>
            </div>
            <div className='board-row'>
                <Squares value={BoardGrid[3]} onSquareClick={()=>ProcessMove(3)}/>
                <Squares value={BoardGrid[4]} onSquareClick={()=>ProcessMove(4)}/>
                <Squares value={BoardGrid[5]} onSquareClick={()=>ProcessMove(5)}/>
            </div>
            <div className='board-row'>
                <Squares value={BoardGrid[6]} onSquareClick={()=>ProcessMove(6)}/>
                <Squares value={BoardGrid[7]} onSquareClick={()=>ProcessMove(7)}/>
                <Squares value={BoardGrid[8]} onSquareClick={()=>ProcessMove(8)}/>
            </div>
        </div>
        </Grid>
        </>
    )
}

function BotStartsFirst({ BoardGrid,SetBoardGrid,botStart,SetBotStart,PlainBoard }) {
    
    function OnSwitch() {
        SetBotStart((prev)=>!prev)
        if (!botStart) {
            if (BoardGrid.filter(x=>x).length===0) {
                const NewBoard=BoardGrid.slice()
                NewBoard[4]='O'
                SetBoardGrid(NewBoard)
            }
        } else {
            if (BoardGrid.filter(x=>x).length===1) {
                SetBoardGrid(PlainBoard)
            }
        }
    };
    return (
        <div className='control-panel-item'>
        <form className='control-panel-form'>
            <Switch value={botStart} size='small' onClick={()=>OnSwitch()}/>
            <label>Bot Starts First</label>
        </form>
        </div>
    )
};

function App() {
    const [BoardGrid,SetBoardGrid]=useState(Array(9).fill(null));
    const PlainBoard=BoardGrid.splice();
    const [CurrentPlayer,SetCurrentPlayer]=useState('X');
    const [isGameOver,setIsGameOver]=useState(false);
    const [GameMessage,SetGameMessage]=useState('Current Player: X');
    const [BotStart,SetBotStart]=useState(false);
    const [RestartClass,SetRestartClass]=useState('restart-icon');
    const [IsExploding, SetIsExploding]=useState(false);
    const [ShowLosePopup,SetShowLosePopup]=useState(false);
    const [GamesPlayed,SetGamesPlayed]=useState(0);
    const [GamesLost,SetGamesLost]=useState(0);

    function RestartGame() {
        function ResetGame() {
            SetBoardGrid(PlainBoard);
            setIsGameOver(false);
            SetCurrentPlayer(BotStart?'O':'X');
            SetGameMessage('Current Player: X');
            SetRestartClass(RestartClass==='restart-icon'?'restart-icon rotate':'restart-icon');
            SetIsExploding(false);
        }
        return (
            <div>
            <form className='control-panel-restart-form'>
                <button className='restart-button' onClick={()=>ResetGame()}>
                    <span><img src={restart} alt='restart' className={RestartClass}></img></span>
                </button>
                <label>Restart Game</label>
            </form>
            </div>
        )
    };

    useEffect(()=>{
        if (BotStart && BoardGrid.filter(x=>x).length===0) {
            const NewBoard=BoardGrid.slice()
            NewBoard[4]='O'
            SetBoardGrid(NewBoard)
        };
    },[BotStart,BoardGrid]);

    useEffect(()=>{
        if (ShowLosePopup) {
            setTimeout(() => {
               SetShowLosePopup(false) 
            }, 2000);
        };
    },[ShowLosePopup,SetShowLosePopup]);

    return (
        <>
        <div className='game-title'>I am the Tic Tac God Bot</div>
        <div className='game-subtitle'>you can try but you cannot defeat me</div>
        <br></br>
        <div className='game-count'>Total Games Played: {GamesPlayed}</div>
        <div className='game-count'>Total Games You Won: 0</div>
        <div className='game-count'>Total Games You Lost: {GamesLost}</div>
        <br></br>
        <br></br>
        <Grid container>
            <Board 
                BoardGrid={BoardGrid} SetBoardGrid={SetBoardGrid}
                CurrentPlayer={CurrentPlayer} SetCurrentPlayer={SetCurrentPlayer}
                isGameOver={isGameOver} setIsGameOver={setIsGameOver}
                GameMessage={GameMessage} SetGameMessage={SetGameMessage} 
                IsExploding={IsExploding} SetIsExploding={SetIsExploding}
                ShowLosePopup={ShowLosePopup} SetShowLosePopup={SetShowLosePopup} 
                SetGamesPlayed={SetGamesPlayed} SetGamesLost={SetGamesLost} />
            
            <Popup visible={ShowLosePopup} className='lose-popup'>
                <div>
                    {
                        IsExploding && 
                        <ConfettiExplosion force={0.8} duration={3000} particleCount={250} width={1600} />
                    }
                </div>
                <div>Congrats you have lost!</div><br></br>
                <button onClick={()=>SetShowLosePopup(false)} className='lose-popup-ack'>ok i am loser</button>
            </Popup>
            <Stack className='control-panel-stack'>
                <span className='control-panel-title'>Control Panel</span>
                <RestartGame />
                <BotStartsFirst 
                    BoardGrid={BoardGrid} SetBoardGrid={SetBoardGrid}
                    botStart={BotStart} SetBotStart={SetBotStart} 
                    PlainBoard={PlainBoard}/>
            </Stack>
        </Grid>
        </>
    )
};

export default App;