import { useEffect,useState,useRef } from 'react'
import { NavLink } from 'react-router-dom';

export default function MenuItems({ InputMenuItemsData }) {
    return (
        <>
        <GetMenu InputMenuItemsData={InputMenuItemsData} />
        </>
    )
}

function GetMenu({ InputMenuItemsData }) {
    return (
        <>
            {InputMenuItemsData.map((item,index)=>(
                <GetMenuItems item={item} index={index} depthLevel={0} />
            ))}
        </>
    )
};

function GetMenuItems ({ item,index,depthLevel }) {
    const [Dropdown,SetDropdown]=useState(false);
    let ref=useRef();
    
    useEffect(()=>{
        const handler=(event)=>{
            if (Dropdown && ref.current && !ref.current.contains(event.target)) {
                SetDropdown(false)
            }
        }
        document.addEventListener('mousedown',handler);
        document.addEventListener('touchstart',handler);
        return ()=>{
            document.removeEventListener('mousedown',handler);
            document.removeEventListener('touchstart',handler);
        }
    },[Dropdown,ref])

    return (
        item.submenu ? (
        <li key={index} className='menu-item' ref={ref}>
            <button type='button' aria-expanded={Dropdown?'true':'false'} onClick={()=>SetDropdown((prev)=>!prev)} className='button'> 
                {item.title}
            </button>
            <DropdownMenu dropdown={Dropdown} setDropdown={SetDropdown} items={item.submenu} depthLevel={depthLevel}/>
        </li>
        ) : (
        <li key={index} className='menu-item'>
            <NavLink to={item.url} role='button'>
                {item.title}
            </NavLink>
        </li>
        )
    )
};

function DropdownMenu({ dropdown,setDropdown,items,depthLevel }) {
    const DropdownCSS=depthLevel===0?'dropdown':'sub-dropdown'
    return (
        <ul className={`${DropdownCSS} ${dropdown?'show':''}`} onClick={()=>setDropdown(false)}>
            {items.map((item,index)=>(
                <GetMenuItems item={item} index={index} depthLevel={1}/>
            ))}
        </ul>
    )
}