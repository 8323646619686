export default function TitleBar({ applicationTitle,applicationSubtitle,applicationlogo,userlogo,applicationgif }) {
    return (
        <>
        <div className="title-bar">
            <img src={applicationlogo} alt='application-logo' className="logo-application"></img>
            <div className="title-area">
                <div className="title-area-title">{applicationTitle}</div>
                <div className="title-area-subtitle">{applicationSubtitle}</div>
            </div>
            {/* <img src={applicationgif} alt='application-gif' className='gif-application'></img> */}
            <img src={userlogo} alt='user-logo' className="logo-user"></img>
        </div>
        </>
    )
}