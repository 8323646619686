import './Header.css'
import TitleBar from './TitleBar'
import MenuBar from './MenuBar'

export default function Header({ applicationTitle,applicationSubtitle,applicationlogo,userlogo,applicationgif }) {
    return (
        <>
        <div className="header-bar">
            <TitleBar 
                applicationTitle={applicationTitle} 
                applicationSubtitle={applicationSubtitle} 
                applicationlogo={applicationlogo} 
                userlogo={userlogo} 
                applicationgif={applicationgif} />
            <MenuBar />
        </div>
        </>
    )
};