import MenuItems from "./MenuItems"
import { MenuItemsData } from "./MenuItemsData"

export default function MenuBar() {
    return (
        <>
        <div className="menu-bar">
            <div className="title-area"></div>
            <MenuItems InputMenuItemsData={MenuItemsData}/>
        </div>
        </>
    )
}