export const MenuItemsData=[
    {
        title:'Home',
        url:'/Home',
    },
    {
        title:'Games',
        url:'/',
        submenu:[
            {
                title:'TicTac GodBot',
                url:'/TicTacGodBot',
            },
            {
                title:'Click Game',
                url:'/ClickGame',
            },
        ]
    },
]